/* eslint-disable max-len */
import * as React from 'react';

import { Header, Container } from '@appbuckets/react-bucket';


const TermsOfService: React.VFC = () => (
  <Container className={'pt-8 pb-8'}>
    <Header
      divided
      textAlign={'center'}
      content={'Termini di Servizio'}
      subheader={'ai sensi dell\'art. 14 del RGPD 679/2016'}
    />

    <style>
      {'p {margin-top: 8px} ol { list-style: decimal; padding-left: 16px } ol > li { display: list-item; margin-top: 24px; font-size: 1.25em }'}
    </style>

    <p>Proedis S.r.l. e` una societ&agrave;` che si propone di rendere disponibile ai propri utenti registrati un servizio innovativo per la visualizzazione dei dati (di seguito meglio descritti) inerenti il servizio pubblico di raccolta rifiuti e igiene urbana, attraverso l&rsquo;accesso all&rsquo;applicazione &ldquo;EasyW@ste&rdquo; disponibile gratuitamente su PlayStore ed AppleStore.</p>
    <ol>
      <li><strong> Termini e Condizioni di Servizio</strong></li>
    </ol>
    <p>Il presente accordo definisce i Termini e Condizioni di servizio dei servizi (in seguito il &ldquo;Servizio&rdquo; o i &ldquo;Servizi&rdquo;) offerti, mediante l&rsquo;applicazione &ldquo;EasyW@ste&rdquo;, da Proedis S.r.l., con sede legale in Torino, Corso Vittorio Emanuele II n.44, P.IVA 12396040011, casella mail di Posta Elettronica Certificata (PEC) &ndash;&nbsp;
      <u>proedis@pec.it</u>.</p>
    <p>Proedis, nell&rsquo;ambito della propria politica di correttezza e trasparenza, invita ciascun Utente che intende utilizzare ovvero utilizza l&rsquo;Applicazione a leggere con attenzione i Termini e Condizioni di servizio che regolano l&rsquo;uso dei Servizi disponibili (di seguito &ldquo;Termini&rdquo;).</p>
    <p>La registrazione ai Servizi e l&rsquo;espressa accettazione dei Termini in sede di registrazione comporta per ciascun utente (di seguito &ldquo;Utente&rdquo;) l&rsquo;obbligo di rispettare i presenti Termini.</p>
    <p>Laddove l&rsquo;utente crei un account o utilizzi i Servizi per conto di una societ&agrave;`/organizzazione, la parola &ldquo;Utente&rdquo; nell&rsquo;ambito dei Termini si applicano sia all&rsquo;utente che alla societ&agrave;`/organizzazione, come pertinente. Quando crea un Account, l&rsquo;utente assicura e garantisce di essere autorizzato a concedere tutte le autorizzazioni e le licenze fornite negli Accordi e di vincolare la societ&agrave;`/organizzazione al rispetto dei Termini.</p>
    <p>Si possono utilizzare i Servizi solo rispettando i presenti Termini. L&rsquo;Utente pu&ograve;` utilizzare i Servizi solo se dispone della potest&agrave;` di stipulare un contratto con Proedis e se tale potest&agrave;` non gli e` stata revocata in base a una legge applicabile. I Servizi possono essere modificati nel tempo per il miglioramento e l&rsquo;aggiunta di nuove caratteristiche. Possiamo interrompere, sospendere o modificare i Servizi in qualunque momento senza previo avviso. Possiamo anche rimuovere qualsiasi contenuto dai Servizi a nostra unica discrezione.</p>
    <p>I presenti Termini sono consultabili online nella loro versione pi&ugrave;` aggiornata all&rsquo;indirizzo&nbsp;
      <u>https://easywaste.ecoportale.net/termini-di-servizio</u>.</p>
    <ol start={2}>
      <li><strong> Variazione dei Termini e Condizioni di Servizio</strong></li>
    </ol>
    <p>Proedis si riserva il diritto insindacabile di aggiornare e modificare in qualsiasi momento le presenti Condizioni Generali e ciascuno dei documenti da queste richiamato, compresa l&rsquo;Informativa sulla Privacy (di seguito, &ldquo;Privacy Policy&rdquo;).</p>
    <p>Le modifiche e gli aggiornamenti delle Condizioni Generali saranno notificati agli Utenti, con ragionevole preavviso, mediante posta elettronica con espressa richiesta di accettazione in caso di modifiche e/o integrazioni che incidano sui diritti e obblighi degli Utenti relativamente all&rsquo;utilizzazione dei Servizi. Qualora l&rsquo;Utente non concordi con le modifiche apportate alle Condizioni Generali, il funzionamento dell&rsquo;Applicazione sar&agrave;` inibito.</p>
    <p>L&rsquo;Utente potr&agrave;` recedere dal presente richiedendo la cancellazione del proprio account scrivendo all&rsquo;indirizzo email&nbsp;
      <u>info@proedis.net</u>&nbsp;con oggetto &ldquo;Richiesta di cancellazione account&rdquo;.</p>
    <ol start={3}>
      <li><strong> Servizi</strong></li>
    </ol>
    <p>Proedis offre ai propri Utenti un servizio innovativo consistente nella possibilit&agrave;` di:</p>
    <ul>
      <li>Consultare i dati anagrafici relativi alla utenze iscritte a ruolo tributario che risultano ad egli/esso intestate;</li>
      <li>Consultare i dati relativi alle attrezzature assegnate alle utenze intestate;</li>
      <li>Consultare le informazioni relative al Servizio di Igiene Urbana del proprio Comune, compreso il calendario delle raccolte; - Consultare i dati relativi agli svuotamenti delle attrezzature assegnate;</li>
      <li>Consultare i dati relativi ai conferimenti effettuati presso isole ecologiche.</li>
      <li>Prenotare una e/o richiedere un servizio</li>
      <li>Inviare segnalazioni al Gestore dei Servizi</li>
    </ul>
    <ol start={4}>
      <li><strong> Registrazione</strong></li>
    </ol>
    <p>La fruizione dei Servizi e` riservata ai maggiorenni qualora l&rsquo;Utente sia una persona fisica.</p>
    <p>La fruizione dei Servizi e` gratuita, entro determinati limiti e previa Registrazione, secondo le modalit&agrave;` di seguito indicate.</p>
    <p>L&rsquo;Utente che intende registrarsi garantisce, qualora sia una persona fisica, di essere maggiorenne e, in ogni caso, che i dati forniti siano inseriti con il consenso del terzo, assumendo ogni responsabilit&agrave;` in ordine alla correttezza ed alla veridicit&agrave;` delle informazioni fornite. Nel caso vi dovessero essere delle variazioni nei dati forniti dagli Utenti, sar&agrave;` responsabilit&agrave;` di questi ultimi informare Proedis degli aggiornamenti non appena possibile secondo le modalit&agrave;` indicate nei prossimi paragrafi.</p>
    <p><strong>4.1 Modalit&agrave; di Registrazione</strong></p>
    <p>Al fine di fruire i Servizi, l&rsquo;Utente e` tenuto a registrarsi (la &ldquo;Registrazione&rdquo;) fornendo:</p>
    <ul>
      <li>codice fiscale, oppure partita Iva nel caso di soggetto diverso da persona fisica;</li>
      <li>codice utente, fornito dall&rsquo;Ente gestore della Tariffa Rifiuti;</li>
    </ul>
    <p>e indicando:</p>
    <ul>
      <li>un valido indirizzo e-mail, che sar&agrave;` utilizzato come&nbsp;<em>Username</em></li>
      <li>una&nbsp;<em>Password</em>, che sar&agrave;` utilizzata per autorizzare gli accessi all&rsquo;Applicazione
      </li>
    </ul>
    <p>Il sistema, previa verifica della corrispondenza tra codice fiscale (o partita iva) e codice utente, proceder&agrave;` a creare un account personale (di seguito, lo &ldquo;Account&rdquo;) per consentire a Proedis di disporre di un profilo sufficiente per fornire i Servizi.</p>
    <p>L&rsquo;accesso all&rsquo;Applicazione e` consentito mediante utilizzo di username e password scelte in fase di Registrazione, previa conferma dell&rsquo;indirizzo e-mail.</p>
    <p>L&rsquo;Utente si impegna ad avvisare tempestivamente Proedis in caso di sospetta violazione della sicurezza del proprio Account scrivendo a info@proedis.net.</p>
    <p>L&rsquo;Utente potr&agrave;` in qualsiasi momento accedere al proprio Account per aggiornare o modificare i dati inseriti, cliccando su &ldquo;impostazioni&rdquo;. Resta inteso che (i) l&rsquo;Utente sara` il solo responsabile della veridicit&agrave;` e dell&rsquo;aggiornamento di tali informazioni e che (ii) Proedis si riserva la facolt&agrave;` di cancellare gli Account degli Utenti laddove abbia ragione di ritenere che le informazioni fornite non siano veritiere e/o corrette.</p>
    <p><strong>4.2 E-mail</strong></p>
    <p>L&rsquo;Utente che si avvale dei Servizi si impegna a fornire un indirizzo e-mail personale, di cui garantisce di disporre legittimamente ed al quale accede regolarmente.</p>
    <p>L&rsquo;Utente si impegna altres&igrave;` ad aggiornare tempestivamente il proprio indirizzo e-mail registrato in caso di variazione. Gli Account registrati con indirizzi e-mail appartenenti a soggetti diversi dall&rsquo;Utente ovvero con indirizzi e-mail temporanei potranno essere cancellati da Proedis nel caso in cui l&rsquo;Utente non abbia provveduto ad aggiornare o fornire un nuovo indirizzo mail entro il predetto termine.</p>
    <p><strong>4.3 Cancellazione dell'Account</strong></p>
    <p>L&rsquo;Utente potr&agrave;` recedere dal presente richiedendo la cancellazione del proprio account scrivendo all&rsquo;indirizzo email&nbsp;
      <u>info@proedis.net</u>&nbsp;con oggetto &ldquo;Richiesta di cancellazione account&rdquo;.</p>
    <ol start={5}>
      <li><strong> Obblighi dell'Utente</strong></li>
    </ol>
    <p>L&rsquo;Utente e` tenuto a fruire dei Servizi in conformit&agrave;` ai presenti Termini, nonch&eacute;� alle leggi e regolamenti vigenti, e a non violare eventuali diritti di terzi o utilizzare i Servizi in modo inappropriato o indecoroso (es. effettuare spamming, caricare contenuti osceni e/o blasfemi e/o diffamatori e/o contro la morale e/o l&rsquo;ordine pubblico).</p>
    <p>L&rsquo;Utente si impegna a non utilizzare i Servizi correlati per scopi illegali o non contemplati nei presenti Termini. L&rsquo;Utente non pu&ograve;` utilizzare i Servizi correlati in modo da danneggiare o comunque pregiudicare l&rsquo;Applicazione o interferire con l&rsquo;utilizzo e il godimento dei Servizi correlati da parte di altri Utenti.</p>
    <p>L&rsquo;Utente e` tenuto a conservare le credenziali di accesso al proprio Account riservate ed e` responsabile per qualsiasi uso dei Servizi che venga fatto da chiunque acceda utilizzando le sue credenziali. L&rsquo;Utente e` responsabile della salvaguardia delle credenziali che utilizza per accedere ai Servizi e accetta di non svelarle a nessun soggetto terzo. Qualsiasi attivit&agrave;` realizzata utilizzando le Chiavi d&rsquo;Accesso dell&rsquo;Utente (o accesso attraverso sistemi di autenticazione esterni) si considerer&agrave;` effettuata dall&rsquo;Utente al quale le relative Chiavi d&rsquo;Accesso sono associate e l&rsquo;Utente sar&agrave;` ritenuto responsabile di tale utilizzo. L&rsquo;Utente si impegna in ogni caso a non utilizzare macchine, algoritmi, software od altre funzioni automatiche per generare il richiamo di pagine o materiali.</p>
    <ol start={6}>
      <li><strong> Limitazione di Responsabilit&agrave;</strong></li>
    </ol>
    <p>Proedis non sar&agrave;` responsabile per eventuali danni o pregiudizi derivanti dall&rsquo;inaccessibilit&agrave;` ai Servizi derivanti da problemi connessi alla rete, ai provider o a collegamenti telefonici e/o telematici di cui la stessa non abbia il controllo, al mancata compatibilit&agrave;` ovvero al mancato e/o difettoso funzionamento delle apparecchiature elettroniche dell&rsquo;Utente stesso. L&rsquo;Utente e` responsabile della custodia e del corretto utilizzo e conservazione delle proprie credenziali di accesso all&rsquo;Applicazione e conseguentemente Proedis non potr&agrave;` essere ritenuta responsabile per conseguenze dannose o pregiudizi che dovessero derivare a carico dell&rsquo;Utente a fronte di un utilizzo illecito da parte di terzi di tali credenziali qualora non propriamente custodite dall&rsquo;Utente o rese dallo stesso disponibili a terzi.</p>
    <p>Qualora i nostri Servizi fossero utilizzati per conto di un&rsquo;azienda, l&rsquo;azienda dovr&agrave;` accettare i presenti termini. L&rsquo;azienda deve tenere indenne e tutelare Proedis e relativi affiliate, funzionari, agenti e dipendenti da qualsiasi reclamo, causa o azione derivanti da o relativi all&rsquo;utilizzo dei Servizi o alla violazione dei presenti termini, comprese eventuali responsabilit&agrave;` o spese derivanti da reclami, perdite, danni, azioni legali, sentenze, spese processuali e legali.</p>
    <p>Sebbene Proedis si impegni a mantenere l&rsquo;Applicazione aggiornata, a non interrompere o sospendere i Servizi senza preavviso e a correggere tempestivamente eventuali errori/difetti riscontrati nell&rsquo;utilizzo dell&rsquo;applicazione, Proedis non presta alcuna garanzia in merito alla correttezza ed aggiornamento dei contenuti, all&rsquo;erogazione dei Servizi in via continuativa ovvero dell&rsquo;assenza di errori e/o difetti nell&rsquo;utilizzo dell&rsquo;App. Salvi i casi di dolo e colpa grave, Proedis non sar&agrave;` responsabile per eventuali danni o pregiudizi che dovessero derivare a carico degli Utenti come conseguenza di (i) imprecisione o errori dei contenuti dell&rsquo;App, (ii) sospensione, interruzione e/o malfunzionamenti dell&rsquo;App.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <ol start={7}>
      <li><strong> Manleva</strong></li>
    </ol>
    <p>L&rsquo;Utente si impegna a manlevare e tenere indenne Proedis da qualsiasi danno o pretesa di terzi derivante o connessa ad qualsiasi utilizzo improprio dei Servizi ovvero contrario o in violazione delle presenti Condizioni Generali.</p>
    <ol start={8}>
      <li><strong> Interruzione dei Servizi</strong></li>
    </ol>
    <p>Proedis si riserva il diritto di cancellare l&rsquo;Account dell&rsquo;Utente, in qualsiasi momento e con effetto immediato, previa comunicazione scritta all&rsquo;Utente Registrato, in caso di violazione da parte dell&rsquo;Utente degli obblighi a suo carico previsti ai sensi del precedente Articolo 5 (Obblighi dell&rsquo;Utente) e al successivo Articolo 9 (Diritti di propriet&agrave;` intellettuale). L&rsquo;accesso all&rsquo;App potr&agrave;` altres&igrave;` essere temporaneamente interrotto in caso di problemi tecnici o per garantirne la manutenzione. Di tali interruzioni sar&agrave;` data, se possibile, preventiva notifica agli Utenti.</p>
    <p>In qualsiasi momento Proedis potr&agrave;` apportare miglioramenti e/o cambiamenti all&rsquo;Applicazione e ai Servizi qualora ci&ograve;` si renda necessario, inter alia, per migliorare i Servizi, per ragioni tecniche o al fine di conformarsi alla normativa vigente.</p>
    <p>Proedis si riserva infine la facolt&agrave;` di cessare, in tutto o in parte, la prestazione dei Servizi in qualsiasi momento e con ragionevole preavviso. In tali ipotesi, Proedis dara` tempestiva comunicazione agli Utenti via e-mail.</p>
    <ol start={9}>
      <li><strong> Diritti di Propriet&agrave; Intellettuale</strong></li>
    </ol>
    <p>Tutti i segni distintivi (marchi denominativi e figurativi, loghi, etc.) indicati e utilizzati sull&rsquo;Applicazione sono di titolarit&agrave;` di Proedis o nella sua legittima disponibilit&agrave;`.</p>
    <p>Proedis e` titolare esclusiva di tutti i diritti di propriet&agrave;` intellettuale e industriale relativi all&rsquo;applicazione &ldquo;EasyW@ste&rdquo;, ivi inclusi, a titolo esemplificativo, i contenuti, i testi, i disegni, le banche dati, il know-how, i software, i dati e le informazioni contenute o connesse allo stesso (di seguito, complessivamente &ldquo;IP Proedis&rdquo;). Proedis non concede alcuna licenza agli Utenti in relazione all&rsquo;IP Proedis. Agli Utenti non e` permesso, fra l&rsquo;altro, copiare, eseguire framing, riprodurre, tradurre, modificare, distribuire, diffondere, utilizzare in qualsiasi modo o forma, in tutto o in parte, sia a titolo oneroso che gratuito, a fini privati o commerciali, di tutto o singole parti, l&rsquo;IP Proedis, al di fuori delle attivit&agrave;` strettamente necessarie all&rsquo;utilizzazione del Servizio.</p>
    <p>Qualsiasi utilizzo dell&rsquo;Applicazione con finalit&agrave;` e/o con modalit&agrave;` diverse da quelle indicate nei presenti Termini e` da considerarsi non autorizzato da Proedis e sar&agrave;` considerata ad ogni effetto di legge violazione della normativa in materia di propriet&agrave;` intellettuale ed industriale. Proedis si riserva di perseguire ogni utilizzazione non autorizzata, o comunque contraria alla legge, nelle pi&ugrave;` opportune sedi giudiziarie sia civili che penali.</p>
    <ol start={10}>
      <li><strong> Privacy Policy</strong></li>
    </ol>
    <p>Proedis proceder&agrave;` al trattamento dei dati personali degli Utenti nel rispetto della normativa in materia di privacy come definito in dettaglio nell&rsquo;informativa sul trattamento dei dati personali, &ldquo;Privacy Policy&rdquo;. La Privacy Policy e` parte integrante dei presenti Termini ed e` consultabile online nella sua versione pi&ugrave;` aggiornata all&rsquo;indirizzo&nbsp;
      <u>https://easywaste.ecoportale.net/privacy-policy</u>.</p>
    <ol start={11}>
      <li><strong> Disposizioni Finali</strong></li>
    </ol>
    <p>Qualora una o pi&ugrave;` disposizioni dei presenti Termini venga dichiarata invalida o inefficace interamente o parzialmente per una qualsiasi ragione, sar&agrave;` scindibile e non incider&agrave;` sulla validit&agrave;` o sulla esecuzione dei Termini nella loro interezza o di qualsiasi altra clausola o disposizione.</p>
    <ol start={12}>
      <li><strong> Normativa applicabile e Controversie</strong></li>
    </ol>
    <p>I presenti Termini sono regolati dalla legge italiana.</p>
    <p>Ferma restando l&rsquo;applicabilit&agrave;` delle disposizioni inderogabili di legge poste a tutela dei consumatori ai sensi del Decreto Legislativo 6 settembre 2005, n. 206, qualsiasi controversia nascente o comunque connessa ai presenti Termini sar&agrave;` devoluta alla competenza esclusiva del foro di Torino.</p>
    <ol start={13}>
      <li><strong> Comunicazioni</strong></li>
    </ol>
    <p>Ogni comunicazione relativa all&rsquo;esecuzione dei Servizi, potr&agrave;` essere indirizzata a Proedis inviando una email all&rsquo;indirizzo info@proedis.net</p>
  </Container>
);

export default TermsOfService;
