import * as React from 'react';
import * as Yup from 'yup';

import {
  Icon,
  Modal,
  FormikInput,
  FormikCheckbox
} from '@appbuckets/react-bucket';

import withForm from '../_lib/withForm';


/* --------
 * Component Interfaces
 * -------- */
export interface SignupDto {
  acceptTOS: boolean;

  acceptTOC: boolean;

  fiscalCode: string;

  password: string;

  passwordConfirm: string;

  userCode: string;
}


/* --------
 * Component Definition
 * -------- */
const Signup = withForm<SignupDto>({

  displayName: 'SignupForm',

  Content: () => {

    // ----
    // Internal State
    // ----
    const [ userCodeModalOpen, setUserCodeModalOpen ] = React.useState(false);
    const [ fiscalCodeModalOpen, setFiscalCodeModalOpen ] = React.useState(false);


    // ----
    // Handlers
    // ----
    const handleUserCodeModalToggle = React.useCallback(
      () => setUserCodeModalOpen((curr) => !curr),
      []
    );

    const handleFiscalCodeModalToggle = React.useCallback(
      () => setFiscalCodeModalOpen((curr) => !curr),
      []
    );


    // ----
    // Component Render
    // ----
    return (
      <React.Fragment>
        <FormikInput
          required={true}
          name={'fiscalCode'}
          label={(
            <span>
              Codice Fiscale / Partita IVA
              <Icon name={'question circle'} onClick={handleFiscalCodeModalToggle} />
            </span>
          )}
        />

        <FormikInput
          required={true}
          name={'userCode'}
          label={(
            <span>
              Codice Utenza
              <Icon name={'question circle'} onClick={handleUserCodeModalToggle} />
            </span>
          )}
        />

        <FormikInput
          required={true}
          name={'password'}
          label={'Password'}
          type={'password'}
        />

        <FormikInput
          required={true}
          name={'passwordConfirm'}
          label={'Conferma Password'}
          type={'password'}
        />

        <FormikCheckbox
          required
          name={'acceptTOS'}
          label={(
            <span>
              Accetto i{' '}
              <a
                rel={'noopener noreferrer'}
                target={'_blank'}
                href={'/termini-di-servizio'}
                onClick={(event) => event.stopPropagation()}
              >
                Termini di Servizio
              </a>
            </span>
          )}
        />

        <FormikCheckbox
          required
          name={'acceptTOC'}
          label={(
            <span>
              Accetto la{' '}
              <a
                rel={'noopener noreferrer'}
                target={'_blank'}
                href={'/privacy-policy'}
                onClick={(event) => event.stopPropagation()}
              >
                Privacy Policy
              </a>
            </span>
          )}
        />

        {userCodeModalOpen && (
          <Modal
            open
            header={'Codice Utenza'}
            content={(
              <Modal.Content>
                Inserire il Codice Identificativo del contribuente o {'dell\'Utenza'} (domestica e/o non domestica){' '}
                iscritta a ruolo tariffario.<br />
                Il codice è reperibile nell{'\''}intestazione della bolletta rifiuti.
              </Modal.Content>
            )}
            onClose={handleUserCodeModalToggle}
          />
        )}

        {fiscalCodeModalOpen && (
          <Modal
            open
            header={'Codice Fiscale / Partita IVA'}
            content={(
              <Modal.Content>
                Inserire il Codice Fiscale o la Partita IVA dell{'\''}intestatario Ta.Ri.<br />
                Nel caso in cui il numero della Partita IVA iniziasse con uno o più zeri, potrebbe essere necessario{' '}
                inserire il numero privo degli zeri iniziali.
              </Modal.Content>
            )}
            onClose={handleFiscalCodeModalToggle}
          />
        )}
      </React.Fragment>
    );
  },

  defaultProps: {
    submitButton: {
      content: 'Registrati',
      full   : true
    }
  },

  toast: {
    onError: undefined
  },

  schema: {
    acceptTOC      : Yup.bool().oneOf([ true ], 'Obbligatorio').required(),
    acceptTOS      : Yup.bool().oneOf([ true ], 'Obbligatorio').required(),
    fiscalCode     : Yup.string().required('Codice Fiscale Obbligatorio'),
    password       : Yup.string()
      .required('Inserisci una Password')
      .min(8, 'Lunghezza minima : 8 caratteri')
      .matches(/[A-Z]/, 'Inserisci una lettera Maiuscola')
      .matches(/[a-z]/, 'Inserisci una lettera Minuscola')
      .matches(/[1-9]/, 'Inserisci un numero'),
    passwordConfirm: Yup.string()
      .required('Conferma la Password')
      .oneOf([ Yup.ref('password') ], 'Le Password non Corrispondono'),
    userCode       : Yup.string().required('Codice Utenza Obbligatorio')
  },

  onSubmit: async (data, { client, setError }) => {
    /** Send the Data to API */
    const [ error, result ] = await client.willRequest({
      method         : 'POST',
      url            : 'auth/signup',
      withAccessToken: false,
      data
    });

    if (error) {
      switch (error.error) {
        case 'auth/duplicate-entry':
          setError({
            statusCode: error.statusCode,
            error     : 'Già Registrato',
            message   : 'I dati inseriti sono già stati utilizzati per la registrazione'
          });
          break;

        default:
          switch (error.statusCode) {
            case 404:
              setError({
                statusCode: 404,
                error     : 'Utenza non trovata',
                message   : 'Nessuna utenza è stata trovata utilizzando i dati inseriti'
              });
              break;

            default:
              setError({
                statusCode: 500,
                error     : 'Errore',
                message   : 'Si è verificato un errore durante la registrazione'
              });
          }
      }

      throw error;
    }

    return result;
  }

});

export { Signup };
