import * as React from 'react';
import invariant from 'tiny-invariant';

import Client from '../lib/client';

/* --------
 * Create the Client Context
 * -------- */
function createClientContext() {
  /** Create the Context */
  const clientContext = React.createContext<Client | undefined>(undefined);

  /** Create an Hook the get the Client Context */
  function useClientHook(): Client {
    /** Get the value of the context */
    const client = React.useContext(clientContext);
    /** Assert value exists */
    invariant(
      client !== undefined,
      'useClient hook must be invoked inside the Client Context'
    );
    /** Return the client */
    return client;
  }

  /** Return the built context */
  return [ useClientHook, clientContext.Provider, clientContext.Consumer ] as const;
}

const [
  useClient,
  ClientProvider,
  ClientConsumer
] = createClientContext();

export {
  useClient,
  ClientProvider,
  ClientConsumer
};
