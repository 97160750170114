import * as React from 'react';
import { Deferred } from 'app/utils';
import invariant from 'tiny-invariant';

import { useClient } from '../context/client.context';

import { ClientRequest, ClientRequestError, ClientState, GenericAPIResponse } from '../lib/client.interfaces';

import {
  UseClientRequestConfig,
  UseClientRequestContext,
  UseClientRequestInternalState,
  UseClientRequestState
} from './hooks.types';


/* --------
 * Hooks Interfaces and Types
 * -------- */
export type UseRequestResponse<T> =
  | { requestTime: number, error: null, isLoading: boolean, response: null }
  | { requestTime: number, error: ClientRequestError, isLoading: boolean, response: null }
  | { requestTime: number, error: null, isLoading: boolean, response: T };


/* --------
 * Hooks Functions
 * -------- */

/**
 * Build an Hook to retrieve the current
 * Client state and its data.
 * Hook contains a function to updated the state
 */
export function useClientState<State extends 'logged' | 'not-logged'>(): [ ClientState<State>, (newState: Partial<ClientState>) => void ] {
  /** Get the client */
  const client = useClient();

  /** Create the Client State */
  const [ clientState, setNewClientState ] = React.useState(client.state);

  /** Use an effect to subscribe to client state change */
  React.useEffect(() => client.subscribeToClientStateChange(setNewClientState), [ client ]);

  /** Return current state */
  return [ clientState, client.setState.bind(client) ];
}


const ClientRequestContext = React.createContext<Omit<UseClientRequestState, 'RequestContext'> | null>(null);
ClientRequestContext.displayName = 'ClientRequestContext';

export function useClientRequest<Response = GenericAPIResponse>(
  config: UseClientRequestConfig<Response>
): UseClientRequestState<Response> & UseClientRequestContext {

  /** Get Configuration */
  const {
    autoReload,
    autoStart = true,
    formatters = [],
    method = 'GET',
    reloadDependencies = [],
    request,
    url,
    initialState
  } = config;

  /** Get the Client */
  const client = useClient();

  /** Initialize the auto reload timeout */
  const [ autoReloadTimeout, setAutoReloadTimeout ] = React.useState(autoReload);

  const [ deferredResponse, setDeferredResponse ] = React.useState(new Deferred<Response>());

  /** Build the initial Request State */
  const [ requestState, setRequestState ] = React.useState<UseClientRequestInternalState<Response>>({
    isLoading  : true,
    isStarted  : false,
    error      : null,
    response   : initialState as Response,
    requestTime: null
  });

  /** Build the Function to Fetch data using client */
  const fetchRequest = React.useCallback(
    async (silent?: boolean) => {
      /** Set loading state only if is reloading not in silent mode */
      if (!silent && !requestState.isLoading) {
        setRequestState((curr) => ({
          ...curr,
          isLoading: true
        }));
      }

      /** Reload the Promise */
      let requestDefer = deferredResponse;

      /** Replace the Defer only on Silent Request */
      if (requestDefer.isFulfilled && !silent) {
        requestDefer = new Deferred<Response>();
        setDeferredResponse(requestDefer);
      }

      /** Make the request */
      const clientRequest: ClientRequest = {
        url,
        method,
        ...request
      };

      const [ error, response ] = await client.willRequest<Response>(clientRequest);

      /** Format data using formatters */
      const formattedResponse = error
        ? undefined
        : formatters.reduce((prev, next) => next(prev), response);

      /** Set the new State */
      setRequestState({
        error,
        isLoading  : false,
        isStarted  : true,
        requestTime: Date.now(),
        response   : (formattedResponse ?? initialState) as Response
      });

      /** Fulfill the promise, or set the promise as fulfilled on silent reload */
      if (!silent) {
        if (!requestDefer.isFulfilled) {
          if (error) {
            requestDefer.reject(error);
          }
          else {
            requestDefer.resolve(formattedResponse);
          }
        }
      }
      else {
        const newDefer = new Deferred<Response>();
        if (error) {
          newDefer.reject(error);
        }
        else {
          newDefer.resolve(formattedResponse);
        }
        setDeferredResponse(newDefer);
      }
    },
    [
      client,
      deferredResponse,
      formatters,
      initialState,
      method,
      request,
      requestState.isLoading,
      url
    ]
  );

  /** Build the Utils */
  const pauseAutoReload = React.useCallback(
    () => setAutoReloadTimeout(0),
    [ setAutoReloadTimeout ]
  );

  const restoreAutoReload = React.useCallback(
    () => setAutoReloadTimeout(autoReload),
    [ setAutoReloadTimeout, autoReload ]
  );

  /** Fetch data first time */
  React.useEffect(
    () => {
      if (!autoStart && requestState.requestTime === null) {
        return;
      }

      fetchRequest();
    },
    // eslint-disable-next-line
    reloadDependencies
  );

  /** Use the AutoReload Interval */
  React.useEffect(
    () => {
      /** If no AutoReload, return */
      if (!autoReloadTimeout) {
        return () => null;
      }

      /** Build the interval */
      const autoReloadInterval = setInterval(() => {
        fetchRequest();
      }, autoReloadTimeout);

      /** Remove interval on clear effect */
      return () => {
        if (autoReloadInterval) {
          clearInterval(autoReloadInterval);
        }
      };
    },
    // eslint-disable-next-line
    [ autoReloadTimeout ]
  );

  /** Set up a Promise Handler */
  const handleAwaitPromise = React.useCallback(
    () => new Promise<Response>((resolve, reject) => {
      deferredResponse.promise
        .then(resolve)
        .catch(reject);
    }),
    [ deferredResponse ]
  );

  /** Build the return state */
  const hook = React.useMemo(
    (): UseClientRequestState<Response> => ({
      await      : handleAwaitPromise,
      error      : requestState.error,
      isLoading  : requestState.isLoading,
      isStarted  : requestState.isStarted,
      pauseAutoReload,
      reload(): Promise<void> {
        return fetchRequest(true);
      },
      requestTime: requestState.requestTime,
      response   : requestState.response,
      restoreAutoReload,
      start() {
        return fetchRequest();
      }
    }),
    [
      handleAwaitPromise,
      requestState.error,
      requestState.isLoading,
      requestState.isStarted,
      pauseAutoReload,
      fetchRequest,
      requestState.requestTime,
      requestState.response,
      restoreAutoReload
    ]
  );

  /** Set up the Context */
  const RequestContext = React.useMemo(
    (): React.ComponentType<React.PropsWithChildren<{}>> => (props) => (
      <ClientRequestContext.Provider value={hook}>
        {props.children}
      </ClientRequestContext.Provider>
    ),
    [ hook ]
  );

  return {
    ...hook,
    RequestContext
  };
}


export function useRequestState<Response = GenericAPIResponse>(): Omit<UseClientRequestState<Response>, 'RequestContext'> {

  const ctx = React.useContext(ClientRequestContext);

  if (process.env.NODE_ENV === 'development') {
    invariant(
      ctx !== undefined,
      'An invalid useRequestState Hook has been used. Please use the Hook inside the right context'
    );
  }

  return ctx as Omit<UseClientRequestState<Response>, 'RequestContext'>;
}
