import * as React from 'react';

import {
  InputProps,
  RxTableColumnProps,
  Box,
  Container,
  Header,
  Input,
  RxTable
} from '@appbuckets/react-bucket';

import { PageComponent } from '../../routes/app-router';

import dictionary from './dictionary.json';


type Dictionary = { material: string, binType: string };

const columns: RxTableColumnProps<Dictionary>[] = [
  {
    key   : 'material',
    header: 'Materiale'
  },
  {
    key   : 'binType',
    header: 'Conferimento'
  }
];

const WasteDictionary: PageComponent<'WasteDictionary'> = () => {

  const [ filter, setFilter ] = React.useState('');

  const handleFilterChange = React.useCallback(
    (e: any, inputProps: InputProps) => {
      setFilter(inputProps.value || '');
    },
    []
  );

  const tableData = React.useMemo(
    (): Dictionary[] => {
      if (!filter) {
        return dictionary;
      }

      const filterRegExp = new RegExp(filter, 'ig');

      return dictionary.filter(({ material }) => filterRegExp.test(material));
    },
    [ filter ]
  );

  return (
    <Container fixedTo={'tablet'}>
      <Header
        icon={{
          name   : 'dumpster',
          primary: true
        }}
        className={'mt-6'}
        textAlign={'center'}
        content={'Dizionario Rifiuti'}
        subheader={'Indicazioni utili per il conferimento dei tuoi rifiuti'}
        size={'large'}
      />

      <Box mb={6}>
        <Input
          icon={'search'}
          placeholder={'Cerca Rifiuto...'}
          value={filter}
          onChange={handleFilterChange}
        />
      </Box>

      <RxTable
        className={'dictionary-table'}
        getRowKey={'material'}
        columns={columns}
        data={tableData}
      />
    </Container>
  );
};

export default WasteDictionary;
