import * as React from 'react';

import { ValidationError } from 'yup';
import ButterToast, { RaiseParam } from 'butter-toast';

import { Toast, ToastProps } from '@appbuckets/react-bucket';

import { isObject } from '../../utils';
import { ClientRequestError } from '../client';


/* --------
 * Notification Manager Type and Interfaces
 * -------- */
export type NotificationContent = string | React.ReactNode | ToastProps | ValidationError | ClientRequestError;


/* --------
 * Create the TrayManager
 * -------- */
class NotificationManager {

  /* --------
   * Constructor Function
   * -------- */
  constructor(
    private readonly namespace: string,
    private readonly Component: React.ComponentType<ToastProps>,
    private readonly defaultProps?: Partial<ToastProps>
  ) {
  }


  /* --------
   * Show Function
   * -------- */
  private show(content?: NotificationContent, options?: RaiseParam, overrideProps?: Partial<ToastProps>) {
    /**
     * Check first if toast content is a ValidationError
     * generate from Yup. In this case, must show a toast
     * for each single validation object
     */
    if (content instanceof ValidationError) {
      content.errors.forEach((error) => {
        this.show(error, options, overrideProps);
      });
      return;
    }

    /**
     * Cast string content to toast props
     */
    const componentProps = ((): ToastProps | undefined => {
      /** If no content, return undefined */
      if (content === null || content === undefined) {
        return undefined;
      }

      /** Plain String must be transformed into a valid object */
      if (typeof content === 'string') {
        return { header: content };
      }

      /** Transform Client Request Error */
      if (typeof (content as any).statusCode === 'number') {
        return {
          header : (content as ClientRequestError).error,
          content: (content as ClientRequestError).message
        };
      }

      return content as any;
    })();

    /**
     * If content is not a valid Notification Content
     * (eg. has is nil), stop the notification
     * show process and return
     */
    if (!isObject(componentProps)) {
      return;
    }

    /**
     * A ClientRequestError could be recognized
     * parsing it statusCode property.
     * In this case must transform to a valid
     * ToastProps object
     */
    // const transformedObject: ToastProps = typeof com

    /**
     * Build the Toast Props, using defaultProps
     * and overridden Props
     */
    const toastProps: ToastProps = {
      ...this.defaultProps,
      ...componentProps,
      ...overrideProps
    };

    const { Component } = this;

    /** Raise the Notification */
    ButterToast.raise({
      namespace: this.namespace,
      timeout  : 6000,
      ...options,
      content  : (props) => {

        const handleDismiss = () => {
          if (props.dismiss && toastProps.dismissible) {
            props.dismiss();
          }
        };

        return <Component {...toastProps} dismiss={handleDismiss} />;
      }
    });
  }


  default = (props: NotificationContent, options?: RaiseParam) => (
    this.show(props, options, { dismissible: 'times' })
  );

  error = (props: NotificationContent, options?: RaiseParam) => (
    this.show(props, options, { dismissible: 'times', danger: true, icon: 'times circle' })
  );

  info = (props: NotificationContent, options?: RaiseParam) => (
    this.show(props, options, { dismissible: 'times', info: true, icon: 'info' })
  );

  primary = (props: NotificationContent, options?: RaiseParam) => (
    this.show(props, options, { dismissible: 'times', primary: true })
  );

  secondary = (props: NotificationContent, options?: RaiseParam) => (
    this.show(props, options, { dismissible: 'times', secondary: true })
  );

  success = (props: NotificationContent, options?: RaiseParam) => (
    this.show(props, options, { dismissible: 'times', success: true, icon: 'check' })
  );

  warning = (props: NotificationContent, options?: RaiseParam) => (
    this.show(props, options, { dismissible: 'times', warning: true, icon: 'exclamation circle' })
  );

}

const ToastController = new NotificationManager('toast-container', Toast);

export { ToastController as Toast };
