import * as React from 'react';
import * as Yup from 'yup';

import { FormikInput } from '@appbuckets/react-bucket';

import withForm from '../_lib/withForm';


/* --------
 * Component Interfaces
 * -------- */
export interface PasswordResetDto {
  fiscalCode: string;

  password: string;

  passwordConfirm: string;

  userCode: string;
}


/* --------
 * Component Definition
 * -------- */
const PasswordReset = withForm<PasswordResetDto>({

  displayName: 'PasswordResetForm',

  Content: () => (
    <React.Fragment>
      <FormikInput
        required={true}
        name={'fiscalCode'}
        label={'Codice Fiscale / Partita IVA'}
      />

      <FormikInput
        required={true}
        name={'userCode'}
        label={'Codice Utenza'}
      />

      <FormikInput
        required={true}
        name={'password'}
        label={'Password'}
        type={'password'}
      />

      <FormikInput
        required={true}
        name={'passwordConfirm'}
        label={'Conferma Password'}
        type={'password'}
      />
    </React.Fragment>
  ),

  defaultProps: {
    submitButton: {
      content: 'Registrati',
      full   : true
    }
  },

  toast: {
    onError: undefined
  },

  schema: {
    fiscalCode     : Yup.string().required('Codice Fiscale Obbligatorio'),
    password       : Yup.string()
      .required('Inserisci una Password')
      .min(8, 'Lunghezza minima : 8 caratteri')
      .matches(/[A-Z]/, 'Inserisci una lettera Maiuscola')
      .matches(/[a-z]/, 'Inserisci una lettera Minuscola')
      .matches(/[1-9]/, 'Inserisci un numero'),
    passwordConfirm: Yup.string()
      .required('Conferma la Password')
      .oneOf([ Yup.ref('password') ], 'Le Password non Corrispondono'),
    userCode       : Yup.string().required('Codice Utenza Obbligatorio')
  },

  onSubmit: async (data, { client, setError }) => {
    /** Send the Data to API */
    const [ error, result ] = await client.willRequest({
      method         : 'POST',
      url            : 'auth/password-reset',
      withAccessToken: false,
      data
    });

    if (error) {
      switch (error.statusCode) {
        case 404:
          setError({
            statusCode: error.statusCode,
            error     : 'Utente Non Trovato',
            message   : 'I dati inseriti non sono riconducibili a nessun utente registrato.'
          });
          break;

        default:
          setError({
            statusCode: 500,
            error     : 'Error',
            message   : 'Si è verificato un errore durante la reimpostazione della password'
          });
      }

      throw error;
    }

    return result;
  }

});

export { PasswordReset };
