import * as React from 'react';

import { Container, Header } from '@appbuckets/react-bucket';

import { Auth as AuthAction } from 'app/actions';


/* --------
 * Component Interfaces
 * -------- */
export interface PasswordResetProps {

}


/* --------
 * Component Definition
 * -------- */
const PasswordReset: React.FunctionComponent<PasswordResetProps> = () => {

  const [ hasRequestedReset, setHasRequestedReset ] = React.useState(false);


  // ----
  // Handlers
  // ----
  const handleHasRequestedReset = React.useCallback(
    () => {
      setHasRequestedReset(true);
    },
    [ setHasRequestedReset ]
  );

  /** A user that hasn't request reset will se the Form */
  if (!hasRequestedReset) {
    return (
      <Container fixedTo={'phone'}>
        <Header
          textAlign={'center'}
          className={'mt-4 px-6'}
          content={'Reimposta Password'}
          subheader={'Inserisci i dati relativi ad una tua utenza Ta.Ri.'}
        />
        <AuthAction.PasswordReset
          onActionComplete={handleHasRequestedReset}
        />
      </Container>
    );
  }

  return (
    <Container fixedTo={'phone'}>
      <Header
        textAlign={'center'}
        icon={{
          name   : 'thumbs up',
          primary: true
        }}
        className={'mt-8 px-6'}
        content={'Operazione Completata'}
        subheader={'Complimenti, la tua Password è stata reimpostata correttamente.'}
      />
    </Container>
  );
};

PasswordReset.displayName = 'PasswordReset';

export default PasswordReset;
