/* eslint-disable max-len */
import * as React from 'react';

import { Header, Container } from '@appbuckets/react-bucket';


const PrivacyPolicy: React.VFC = () => (
  <Container className={'pt-8 pb-8'}>
    <Header
      divided
      textAlign={'center'}
      content={'Privacy Policy'}
      subheader={'ai sensi dell\'art. 14 del RGPD 679/2016'}
    />

    <style>
      {'p {margin-top: 8px} p > strong { display: block; margin-top: 24px; font-size: 1.25em }'}
    </style>

    <p>Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti. Gli Utenti possono essere soggetti a livelli di protezione diversi. Alcuni Utenti godono pertanto di superiore protezione. Ulteriori informazioni in merito ai criteri di protezione sono reperibili nella sezione sull&rsquo;applicabilit&agrave;.</p>
    <p><strong>Titolare del Trattamento dei Dati</strong></p>
    <p>PROEDIS SRL - C.so Vittorio Emanuele II n.44 - 10123 Torino (TO)</p>
    <p>Indirizzo Email del Titolare:&nbsp;<u>info@proedis.net</u></p>
    <p>Posta elettronica certificata del Titolare:&nbsp;<u>proedis@pec.it</u></p>
    <p><strong>Provenienza dei dati raccolti</strong></p>
    <p>I Dati Personali possono essere raccolti direttamente dagli utenti o possono essere forniti dall&rsquo;Ente gestore della Tariffa rifiuti.</p>
    <p><strong>Tipologie di Dati Raccolti</strong></p>
    <p>Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze parti, ci sono: Cookie; Dati di utilizzo.</p>
    <p>Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati stessi. I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.</p>
    <p>Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l&rsquo;Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ci&ograve; abbia alcuna conseguenza sulla disponibilit&agrave; del Servizio o sulla sua operativit&agrave;.</p>
    <p>Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a contattare il Titolare. L&rsquo;eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione, ove non diversamente precisato, ha la finalit&agrave; di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalit&agrave;` descritte nel presente documento e nella Cookie Policy, se disponibile.</p>
    <p>L'Utente si assume la responsabilit&agrave;` dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questa Applicazione e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilit&agrave;` verso terzi.</p>
    <p><strong>Modalit&agrave; e Luogo del trattamento dei Dati raccolti</strong></p>
    <p><strong>Modalit&agrave; di Trattamento</strong></p>
    <p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l&rsquo;accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.</p>
    <p>Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalit&agrave;` organizzative e con logiche strettamente correlate alle finalit&agrave;` indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell&rsquo;organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, societ&agrave;` informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L&rsquo;elenco aggiornato dei Responsabili potr&agrave;` sempre essere richiesto al Titolare del Trattamento.</p>
    <p><strong>Base Giuridica del Trattamento</strong></p>
    <p>Per la registrazione all&rsquo;Applicazione, il Titolare potr&agrave; trattare i suoi Dati Personali relativi all&rsquo;Utente previo suo specifico consenso.</p>
    <p>La prestazione del consenso &egrave; obbligatoria e il mancato conferimento comporter&agrave; l&rsquo;impossibilit&agrave; di fornirle i servizi richiesti.</p>
    <p>Potr&agrave; revocare il consenso prestato richiedendo la cancellazione del Suo account, come indicato nei Termini di Servizio.</p>
    <p><strong>Luogo</strong></p>
    <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.</p>
    <p>I Dati Personali dell&rsquo;Utente potrebbero essere trasferiti in un paese diverso da quello in cui l&rsquo;Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l&rsquo;Utente pu&ograve; fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.</p>
    <p>In caso di superiore protezione, l&rsquo;Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al di fuori dell&rsquo;Unione Europea o ad un&rsquo;organizzazione internazionale di diritto internazionale pubblico o costituita da due o pi&ugrave;` paesi, come ad esempio l&rsquo;ONU, nonch&eacute;� in merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati.</p>
    <p>Qualora abbia luogo uno dei trasferimenti appena descritti, l&rsquo;Utente pu&ograve;` fare riferimento alle rispettive sezioni di questo documento o chiedere informazioni al Titolare contattandolo agli estremi riportati in apertura.</p>
    <p><strong>Periodo di Conservazione</strong></p>
    <p>Il Titolare conserver&agrave; i Dati Personali, necessari per la registrazione, sino a quando il consenso al trattamento non venga revocato, salvo necessit&agrave; di conservazione per un periodo pi&ugrave; lungo in ottemperanza ad un obbligo di legge o per ordine di un&rsquo;autorit&agrave;.</p>
    <p>Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilit&agrave;` dei Dati non potranno pi&ugrave;` essere esercitati.</p>
    <p><strong>Finalit&agrave; del Trattamento dei Dati Raccolti</strong></p>
    <p>I Dati dell&rsquo;Utente sono raccolti per consentire al Titolare di fornire i propri Servizi, cosi` come per le seguenti finalit&agrave;`: Visualizzazione di contenuti da piattaforme esterne.</p>
    <p>Per ottenere ulteriori informazioni dettagliate sulle finalit&agrave;` del trattamento e sui Dati Personali concretamente rilevanti per ciascuna finalit&agrave;`, l&rsquo;Utente pu&ograve;` fare riferimento alle relative sezioni di questo documento.</p>
    <p><strong>Dettagli sul Trattamento dei Dati Personali</strong></p>
    <p><strong>Visualizzazione di contenuti da piattaforme esterne</strong></p>
    <p>Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa Applicazione e di interagire con essi.</p>
    <p>Questo tipo di servizio potrebbe comunque raccogliere dati sul traffico web relativo alle pagine dove il servizio e` installato, anche quando gli utenti non lo utilizzano.</p>
    <p><strong>Widget Google Maps (Google Ireland Limited)</strong></p>
    <p>Google Maps e` un servizio di visualizzazione di mappe gestito da Google Ireland Limited che permette a questa Applicazione di integrare tali contenuti all&rsquo;interno delle proprie pagine.<br />Dati Personali raccolti: Cookie; Dati di utilizzo.<br />Luogo del trattamento: Irlanda &ndash; Privacy Policy. Soggetto aderente al Privacy Shield.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>Ulteriori Informazioni sui Dati Personali</strong></p>
    <p><strong>Dati Personali accolti attraverso fonti diverse dall'Utente</strong></p>
    <p>Il Titolare di questa Applicazione potrebbe aver legittimamente raccolto Dati Personali relativi all&rsquo;Utente senza il suo coinvolgimento, attingendo a fonti fornite da terze parti, in conformit&agrave;` con le basi giuridiche descritte nella sezione relativa alle basi giuridiche del trattamento.<br />Qualora il Titolare avesse raccolto Dati Personali in tal modo, l&rsquo;Utente potr&agrave;` trovare informazioni specifiche riguardo alle fonti nelle rispettive sezioni di questo documento o contattando il Titolare.
    </p>
    <p><strong>Identificazione dell'Utente tramite identificativo univoco universale (UUID)</strong></p>
    <p>Questa Applicazione pu&ograve;` tracciare gli Utenti tramite il salvataggio del cosiddetto &ldquo;identificativo univoco universale&rdquo; (UUID) per fini di analisi statistica o per memorizzare le preferenze degli Utenti. Tale identificatore viene generato con l&rsquo;installazione di questa Applicazione, non viene cancellato quando l&rsquo;Applicazione e` chiusa o aggiornata ma viene definitivamente rimosso solo se l&rsquo;Utente decide di disinstallare l&rsquo;Applicazione dal proprio dispositivo. Se l&rsquo;applicazione viene reinstallata e` generato un nuovo UUID.</p>
    <p><strong>Identificazione univoca del dispositivo</strong></p>
    <p>Questa Applicazione pu&ograve;` tracciare gli Utenti tramite il salvataggio di un codice di identificazione univoco del loro dispositivo, per finalit&agrave;` statistiche o per conservare le preferenze degli Utenti.</p>
    <p><strong>Diritti dell'Utente</strong></p>
    <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
    <p>In caso di superiore protezione, l&rsquo;Utente pu&ograve;` esercitare tutti i diritti riportati di seguito. In ogni altro caso, l&rsquo;Utente pu&ograve;` contattare il titolare per scoprire quali diritti siano applicabili nel suo caso e come esercitarli.</p>
    <p>In particolare, l&rsquo;Utente ha il diritto di:</p>
    <ul>
      <li>
        <strong>revocare il consenso in ogni momento.</strong>&nbsp;L&rsquo;Utente pu&ograve;` revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.
      </li>
      <li>
        <strong>opporsi al trattamento dei propri Dati.</strong>&nbsp;L&rsquo;Utente pu&ograve;` opporsi al trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione sottostante.
      </li>
      <li>
        <strong>accedere ai propri Dati.</strong>&nbsp;L&rsquo;Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.
      </li>
      <li>
        <strong>verificare e chiedere la rettificazione.</strong>&nbsp;L&rsquo;Utente pu&ograve;` verificare la correttezza dei propri Dati e richiederne l&rsquo;aggiornamento o la correzione.
      </li>
      <li>
        <strong>ottenere la limitazione del trattamento.</strong>&nbsp;Quando ricorrono determinate condizioni, l&rsquo;Utente pu&ograve;` richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratter&agrave;` i Dati per alcun altro scopo se non la loro conservazione.
      </li>
      <li>
        <strong>ottenere la cancellazione o rimozione dei propri Dati Personali.</strong>&nbsp;Quando ricorrono determinate condizioni, l&rsquo;Utente pu&ograve;` richiedere la cancellazione dei propri Dati da parte del Titolare.
      </li>
      <li>
        <strong>ricevere i propri Dati o farli trasferire ad altro titolare.</strong>&nbsp;L&rsquo;Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa disposizione e` applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento e` basato sul consenso dell&rsquo;Utente, su un contratto di cui l&rsquo;Utente e` parte o su misure contrattuali ad esso connesse.
      </li>
      <li>
        <strong>proporre reclamo.</strong>&nbsp;L&rsquo;Utente pu&ograve;` proporre un reclamo all&rsquo;autorit&agrave;` di controllo della protezione dei dati personali competente o agire in sede giudiziale.
      </li>
    </ul>
    <p><strong>Dettagli sul Diritto di Opposizione</strong></p>
    <p>Quando i Dati Personali sono trattati nell&rsquo;interesse pubblico, nell&rsquo;esercizio di pubblici poteri di cui e` investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
    <p>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalit&agrave;` di marketing diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati con finalit&agrave;` di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.</p>
    <p><strong>Come Esercitare i Diritti</strong></p>
    <p>Per esercitare i diritti dell&rsquo;Utente, gli Utenti possono indirizzare una richiesta agli estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e evase dal Titolare nel pi&ugrave;` breve tempo possibile, in ogni caso entro un mese.</p>
    <p><strong>Applicabilit&agrave; del Livello Superiore di Protezione</strong></p>
    <p>Mentre la maggior parte delle disposizioni di questo documento vale nei confronti di tutti gli Utenti, alcune sono espressamente assoggettate all&rsquo;applicabilit&agrave;` di un livello superiore di protezione al trattamento di Dati Personali.</p>
    <p>Tale livello superiore di protezione e` sempre garantito quando il trattamento:</p>
    <ul>
      <li>e` eseguito da un Titolare con sede nella UE; ovvero</li>
      <li>riguarda Dati Personali di Utenti che si trovano nella UE ed e` funzionale all&rsquo;offerta di beni o servizi a titolo oneroso o gratuito a tali Utenti; ovvero</li>
      <li>riguarda Dati Personali di Utenti che si trovano nella UE e permette al Titolare di monitorare il comportamento di tali Utenti nella misura in cui tale comportamento ha luogo all'interno dell'Unione.</li>
    </ul>
    <p><strong>Ulteriori informazioni sul Trattamento</strong></p>
    <p><strong>Difesa in Giudizio</strong></p>
    <p>I Dati Personali dell&rsquo;Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione o dei Servizi connessi da parte dell&rsquo;Utente. L&rsquo;Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorit&agrave;` pubbliche.</p>
    <p><strong>Informative Specifiche</strong></p>
    <p>Su richiesta dell&rsquo;Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</p>
    <p><strong>Log di Sistema e Manutenzione</strong></p>
    <p>Per necessita` legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l&rsquo;indirizzo IP Utente.</p>
    <p><strong>Informazioni non contenute in questa policy</strong></p>
    <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>
    <p><strong>Risposta alla richieste "Do Not Track"</strong></p>
    <p>Questa Applicazione non supporta le richieste &ldquo;Do Not Track&rdquo;. Per scoprire se gli eventuali servizi di terze parti utilizzati le supportino, l'Utente e` invitato a consultare le rispettive privacy policy.</p>
    <p><strong>Modifiche a questa Privacy Policy</strong></p>
    <p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento dandone informazione agli Utenti su questa pagina e, se possibile, su questa Applicazione nonch&eacute;�, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui e` in possesso il Titolare. Si prega dunque di consultare regolarmente questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.</p>
    <p>Qualora le modifiche interessino trattamenti la cui base giuridica e` il consenso, il Titolare provveder&agrave;` a raccogliere nuovamente il consenso dell&rsquo;Utente, se necessario.</p>
    <p><strong>Definizioni o Riferimenti Legali</strong></p>
    <p><strong>Dati Personali (o Dati)</strong></p>
    <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
    <p><strong>Dati di Utilizzo</strong></p>
    <p>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall&rsquo;Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l&rsquo;orario della richiesta, il metodo utilizzato nell&rsquo;inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all&rsquo;itinerario seguito all&rsquo;interno dell&rsquo;Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all&rsquo;ambiente informatico dell&rsquo;Utente.</p>
    <p><strong>Utente</strong></p>
    <p>L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato.</p>
    <p><strong>Interessato</strong></p>
    <p>La persona fisica cui si riferiscono i Dati Personali.</p>
    <p><strong>Responsabile del Trattamento (o Responsabile)</strong></p>
    <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p><strong>Titolare del Trattamento (o Titolare)</strong></p>
    <p>La persona fisica o giuridica, l'autorit&agrave;` pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalit&agrave;` e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, e` il titolare di questa Applicazione.</p>
    <p><strong>Questa Applicazione</strong></p>
    <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
    <p><strong>Servizio</strong></p>
    <p>Il Servizio fornito da questa Applicazione cosi` come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>
    <p><strong>Unione Europea (o UE)</strong></p>
    <p>Salvo ove diversamente specificato, ogni riferimento all&rsquo;Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell&rsquo;Unione Europea e dello Spazio Economico Europeo.</p>
    <p><strong>Cookie</strong></p>
    <p>Piccola porzione di dati conservata all'interno del dispositivo dell'Utente.</p>
    <p><strong>Riferimenti Legali</strong></p>
    <p>La presente informativa privacy e` redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.</p>
    <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.</p>
    <p><strong>Data Ultimo Aggiornamento</strong></p>
    <p>26 aprile 2021</p>
    <p>&nbsp;</p>
  </Container>
);

export default PrivacyPolicy;
