import * as React from 'react';

import {
  PageComponent as NativePageComponent,
  useCurrentRoute as useNativeCurrentRoute,
  useAppRouterState as useNativeAppRouterState,
  useAppRouter as useNativeAppRouter,
  AppLink as NativeAppLink,
  AppLinkProps
} from '@appbuckets/app-router';

import type { RoutesDefinition } from './routes-definition';


/* --------
 * Redeclare Hooks Function, adding Routes Name
 * -------- */
export function AppLink<RouteName extends keyof RoutesDefinition>(
  appLinkProps: React.PropsWithChildren<AppLinkProps<RoutesDefinition, RouteName>>
) {
  return (
    <NativeAppLink<RoutesDefinition, RouteName>
      {...appLinkProps}
    />
  );
}

export function useAppRouter<RouteName extends keyof RoutesDefinition>() {
  return useNativeAppRouter<RoutesDefinition, RouteName>();
}

export function useAppRouterState() {
  return useNativeAppRouterState<RoutesDefinition>();
}

export function useCurrentRoute<RouteName extends keyof RoutesDefinition>() {
  return useNativeCurrentRoute<RoutesDefinition, RouteName>();
}

export type PageComponent<Name extends keyof RoutesDefinition> = NativePageComponent<RoutesDefinition, Name>;
