import * as Yup from 'yup';

import { AnyObject } from '@appbuckets/react-bucket/build/types/generic';


type InternalNode = {
  _type: string;
  _default?: any;
};

type InternalYupDefinition<Dto> = {
  _nodes?: (keyof Dto)[];
};

export default function initialValuesFromYup<Dto extends AnyObject = AnyObject>(
  schema: Yup.ObjectSchema<Yup.Shape<object | undefined, Dto>>
): Dto {

  const { _nodes } = (schema as unknown as InternalYupDefinition<Dto>);

  const result: Partial<Record<keyof Dto, any>> = {};

  if (!Array.isArray(_nodes)) {
    return result as Dto;
  }

  _nodes.forEach((nodeName) => {
    if (!schema.fields || !schema.fields[nodeName]) {
      return;
    }

    const { _default, _type } = schema.fields[nodeName] as unknown as InternalNode;

    /** Object type must be computed recursively if no default value exists */
    if (_type === 'object') {
      if (typeof _default === 'object' && _default !== null) {
        result[nodeName] = _default;
      }
      else {
        (result as any)[nodeName] = initialValuesFromYup(schema.fields[nodeName] as unknown as Yup.ObjectSchema<Dto>);
      }
    }

    else if (_type === 'boolean') {
      result[nodeName] = !!_default || false;
    }

    else if (_type === 'string') {
      result[nodeName] = _default || '';
    }

    else if (_type === 'array') {
      result[nodeName] = _default || [];
    }

    else if (_type === 'number') {
      result[nodeName] = _default || '';
    }

    else {
      window.console.warn(`Must provide an init value for ${_type}`);
      result[nodeName] = _default || '';
    }

  });

  return result as Dto;
}
