import * as React from 'react';

import { ClientProvider } from '../context/client.context';

import Client from '../lib/client';


type ExtendedProps<P extends {} = {}> = JSX.IntrinsicAttributes & P & { children?: React.ReactNode; };

/** Declare the Init Client Component */
export function initClient<P extends {} = {}>(ChildComponent: React.ComponentType<ExtendedProps<P>>): React.ComponentType<P> {
  return (childComponent: ExtendedProps<P>) => (
    <ClientProvider value={Client.getInstance()}>
      <ChildComponent {...childComponent} />
    </ClientProvider>
  );
}

export default initClient;
