import * as React from 'react';
import * as Yup from 'yup';

import { FormikHelpers, useFormikContext } from 'formik';
import realyFastDeepClone from 'rfdc';

import {
  FormFormik,
  FormFormikActionHandler,
  Modal,
  useAutoControlledValue
} from '@appbuckets/react-bucket';

import {
  AnyObject
} from '@appbuckets/react-bucket/build/types/generic';

import { useClient } from 'app/modules/client';
import { ClientRequestError } from 'app/modules/client/lib/client.interfaces';
import { Toast } from 'app/modules/notifications';
import { RequestError } from 'app/ui';
import { will } from 'app/utils';

import {
  ActionHelpers,
  WithFormConfiguration,
  WrapperComponent, WrapperComponentProps
} from './withForm.types';

import initialValuesFromYup from './initialValuesFromYup';


/* --------
 * Define the HOC function
 * -------- */
export default function withForm<Dto extends AnyObject = AnyObject, Props extends AnyObject = AnyObject, Result = any>(
  config: WithFormConfiguration<Dto, Props, Result>
) {

  /** Get Configuration */
  const {
    Content,
    defaultProps: defaultPropsBuilder,
    displayName,
    extendValidation = true,
    formatData,
    onSubmitError,
    onSubmit,
    overrideProps: overridePropsBuilder,
    parseData,
    schema: schemaConstructor,
    stripUnknown = true,
    toast: defaultToastMessage,
    trigger
  } = config;


  /* --------
   * Define the Inner Wrapper for Content
   * This component will add the Formik Context
   * to defined Content Component
   * -------- */
  const InnerWrapper: React.FunctionComponent<Props & { isEditing: boolean }> = (
    props
  ) => {
    /** Get the Formik Context */
    const formik = useFormikContext<Dto>();
    /** Return the Content */
    return (
      <Content
        {...props}
        formik={formik}
      />
    );
  };


  /* --------
   * Define the Outer Wrapper
   * This will be the Main Form Component
   * returned by the HOC function
   * -------- */
  const OuterWrapper: WrapperComponent<Dto, Result, Props> = (
    props
  ) => {

    const {
      isEditing: userDefinedIsEditing
    } = props;

    /* --------
     * Get Component Props
     * applying default and override props
     * defined into the HOC constructor
     * -------- */
    /** Set if form is starting in data editing mode */
    const couldBeEditing = React.useMemo(
      (): boolean => (
        typeof props.initialValues === 'object'
        && !Array.isArray(props.initialValues)
        && props.initialValues !== null
        && !!Object.keys(props.initialValues).length
      ),
      [ props.initialValues ]
    );

    const isEditing = typeof userDefinedIsEditing === 'boolean'
      ? !!userDefinedIsEditing
      : couldBeEditing;

    /** Compute Default Props */
    const defaultProps = React.useMemo(
      () => {
        if (typeof defaultPropsBuilder === 'function') {
          return defaultPropsBuilder({ ...props, isEditing });
        }
        return defaultPropsBuilder;
      },
      // eslint-disable-next-line
      [ isEditing ]
    );

    /** Compute override Props */
    const overrideProps = React.useMemo(
      () => {
        if (typeof overridePropsBuilder === 'function') {
          return overridePropsBuilder({ ...defaultProps, ...props, isEditing });
        }
        return overridePropsBuilder;
      },
      // eslint-disable-next-line
      [ isEditing ]
    );

    /** Merge all Props */
    const componentProps: WrapperComponentProps<Dto, Result, Props> = {
      ...defaultProps,
      ...props,
      ...overrideProps
    };

    const {
      defaultOpen  : userDefinedDefaultOpen,
      initialValues: userDefinedInitialValues,
      modal,
      modalProps,
      onActionCancel,
      onActionComplete,
      onActionError,
      onSubmit: overriddenOnSubmit,
      open    : userDefinedOpen,
      trigger : userDefinedModalTrigger,
      ...formProps
    } = componentProps;


    /* --------
     * Hooks and State Definition
     * -------- */
    const client = useClient();

    const [ open, trySetModalOpen ] = useAutoControlledValue(false, {
      defaultProp: userDefinedDefaultOpen,
      prop       : userDefinedOpen
    });

    const [ actionError, setActionError ] = React.useState<ClientRequestError>();


    /* --------
     * Schema Building
     * -------- */
    const [ schema ] = React.useState(
      Yup.object().shape<Dto>(
        typeof schemaConstructor === 'function'
          ? schemaConstructor({ ...componentProps, isEditing })
          : schemaConstructor
      )
    );


    /* --------
     * Build initial form values
     * -------- */
    const initialValues = React.useMemo<Dto>(
      () => {
        /** If form is in data editing mode, then clone user defined initial values */
        if ((isEditing || couldBeEditing) && userDefinedInitialValues) {
          const clonedData = realyFastDeepClone()(userDefinedInitialValues);
          const castedData: Dto = (stripUnknown
            ? schema.noUnknown().cast(clonedData)
            : schema.cast(clonedData))!;
          return typeof parseData === 'function'
            ? parseData(castedData, { ...componentProps, isEditing })
            : castedData;
        }
        /** Else, build initial values using utils */
        return initialValuesFromYup<Dto>(schema);
      },
      // eslint-disable-next-line
      [ isEditing, couldBeEditing, schema, userDefinedInitialValues ]
    );


    /* --------
     * Define Form Handlers
     * -------- */
    const handleModalOpen = React.useCallback(
      () => {
        trySetModalOpen(true);
      },
      [ trySetModalOpen ]
    );

    const handleModalClose = React.useCallback(
      () => {
        trySetModalOpen(false);
      },
      [ trySetModalOpen ]
    );

    const handleSubmitClick: FormFormikActionHandler<Dto> = async (
      data, helpers
    ) => {
      /** Build Action Helpers */
      const actionHelpers: ActionHelpers<Dto> = {
        client,
        formik  : helpers as FormikHelpers<Dto>,
        setError: setActionError,
        toast   : Toast
      };

      /** Wrap process into a try catch */
      try {
        /** Check if must extends validation */
        if (extendValidation) {
          const [ validationError ] = await will(
            schema.validate(data)
          );

          if (validationError) {
            const {
              message: errorMessage,
              path   : errorPath
            } = validationError as Yup.ValidationError;

            helpers.setFieldError(errorPath, errorMessage);

            return;
          }
        }

        /** Get the right onSubmit Function */
        const onSubmitFunction = overriddenOnSubmit ?? onSubmit;

        /** Build the Data */
        const dataToUse: Dto = (stripUnknown
          ? schema.noUnknown().cast(data)
          : schema.cast(data))!;

        /** Format data to send to onSubmit Actions */
        const formattedData = typeof formatData === 'function'
          ? formatData(dataToUse, { ...componentProps, isEditing })
          : dataToUse;

        /** Prepare the Result */
        let result: Result | null = null;

        if (typeof onSubmitFunction === 'function') {
          /** Get the Result */
          result = await onSubmitFunction(
            formattedData,
            actionHelpers,
            { ...componentProps, isEditing }
          );
        }

        /** Call the onActionComplete handlers if exists */
        if (onActionComplete) {
          await onActionComplete(result as Result, formattedData, actionHelpers);
        }

        /** Remove the Submitting State, only if is not modal */
        if (!modal) {
          helpers.setSubmitting(false);
        }
        else {
          /** Close the Modal */
          handleModalClose();
        }

        /** Show toast */
        if (defaultToastMessage) {
          if (isEditing && defaultToastMessage.whileEditing) {
            Toast.success(defaultToastMessage.whileEditing);
          }

          if (!isEditing && !defaultToastMessage.whileCreating) {
            Toast.success(defaultToastMessage.whileCreating);
          }
        }
      }
      catch (error) {
        if (onSubmitError) {
          await onSubmitError(error, data, actionHelpers);
        }

        if (onActionError) {
          await onActionError(error, data, actionHelpers);
        }

        if (defaultToastMessage?.onError) {
          if (defaultToastMessage.onError === 'thrown') {
            Toast.error(error);
          }
          else {
            Toast.error(defaultToastMessage.onError);
          }
        }

        /**
         * If no action on error exists, log the error
         * into the console to further debug
         * TODO: Add Rollbar Exception
         */
        if (!onSubmitError && !onActionError) {
          if (process.env.NODE_ENV === 'development') {
            throw new Error(
              'Warn: \n'
              + 'An error not correctly without correct catch occurred while '
              + 'building data in withForm HOC Component.\n'
              + 'This will result in some unexpected behaviour!'
            );
          }
        }
      }
    };

    const handleCancelClick: FormFormikActionHandler<Dto> = () => {
      if (onActionCancel) {
        onActionCancel();
      }

      handleModalClose();
    };


    /* --------
     * Build the Form Element
     * -------- */
    const formElement = (
      <FormFormik
        formActionWrapper={modal ? Modal.Actions : 'div'}
        formContentWrapper={modal ? Modal.Content : 'div'}
        {...formProps}
        initialValues={initialValues as Dto}
        onCancel={handleCancelClick}
        onSubmit={handleSubmitClick}
        validationSchema={schema}
      >
        {actionError && (
          <RequestError
            {...actionError}
          />
        )}
        <InnerWrapper isEditing={isEditing} {...componentProps} />
      </FormFormik>
    );

    if (!modal) {
      return formElement;
    }

    return (
      <Modal
        {...modalProps}
        open={open}
        onOpen={handleModalOpen}
        onClose={handleModalClose}
        trigger={userDefinedModalTrigger ?? trigger}
      >
        {formElement}
      </Modal>
    );
  };

  /** Apply the DisplayName */
  OuterWrapper.displayName = displayName ?? 'FormWrapper';

  /** Return the Wrapper */
  return OuterWrapper;

}
