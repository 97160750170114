import * as React from 'react';

import { Header, Container } from '@appbuckets/react-bucket';

import { Auth as AuthAction } from 'app/actions';


/* --------
 * Component Interfaces
 * -------- */
export interface SignupProps {

}


/* --------
 * Component Definition
 * -------- */
const Signup: React.FunctionComponent<SignupProps> = () => {

  const [ isRegistered, setRegistered ] = React.useState<boolean>(false);


  // ----
  // Handlers
  // ----
  const handleSetRegistered = React.useCallback(
    () => {
      setRegistered(true);
    },
    [ setRegistered ]
  );


  /** Not registered user will see the Form */
  if (!isRegistered) {
    return (
      <Container fixedTo={'phone'}>
        <Header
          textAlign={'center'}
          className={'mt-4 px-6'}
          content={'Benvenuto'}
          subheader={'Inserisci i dati relativi ad una tua utenza Ta.Ri.'}
        />
        <AuthAction.Signup
          onActionComplete={handleSetRegistered}
        />
      </Container>
    );
  }

  return (
    <Container fixedTo={'phone'}>
      <Header
        textAlign={'center'}
        icon={{
          name   : 'thumbs up',
          primary: true
        }}
        className={'mt-8 px-6'}
        content={'Operazione Completata'}
        subheader={'Complimenti, hai completato correttamente la registrazione a EasyWaste'}
      />
    </Container>
  );
};

Signup.displayName = 'Signup';

export default Signup;
