import * as React from 'react';

import { AppRoute } from '@appbuckets/app-router';

import * as Pages from 'app/pages';

import type { RoutesDefinition } from './routes-definition';


/* --------
 * Shared Route Props
 * -------- */
const sharedPublicRoutesProps: Partial<AppRoute<RoutesDefinition>> = {
  exact     : true,
  hasSidebar: false,
  hasNavbar : false,
  isPrivate : false,
  isPublic  : true
};

const sharedPrivateRoutesProps: Partial<AppRoute<RoutesDefinition>> = {
  exact     : true,
  hasHeader : true,
  hasSidebar: false,
  hasNavbar : true,
  isPrivate : true,
  isPublic  : false
};


/* --------
 * Export routes
 * -------- */
export const routes: AppRoute<RoutesDefinition>[] = [

  {
    ...sharedPrivateRoutesProps,
    name     : 'Home',
    path     : `${process.env.PUBLIC_URL}/`,
    isDefault: 'private',
    component: React.Fragment
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'Signup',
    path     : `${process.env.PUBLIC_URL}/signup`,
    isDefault: 'public',
    component: Pages.Auth.Signup,
    title    : 'Registrati'
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'PasswordReset',
    path     : `${process.env.PUBLIC_URL}/password-reset`,
    isDefault: 'public',
    component: Pages.Auth.PasswordReset,
    title    : 'Reimposta Password'
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'WasteDictionary',
    path     : `${process.env.PUBLIC_URL}/dictionary`,
    component: Pages.WasteDictionary,
    title    : 'Dizionario Rifiuti'
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'Infos',
    path     : `${process.env.PUBLIC_URL}/infos`,
    component: Pages.Info,
    title    : 'Informazioni'
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'Surveys',
    path     : `${process.env.PUBLIC_URL}/survey`,
    component: Pages.Surveys,
    title    : 'Sondaggi'
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'PrivacyPolicy',
    path     : `${process.env.PUBLIC_URL}/privacy-policy`,
    component: Pages.System.PrivacyPolicy,
    title    : 'Privacy Policy'
  },

  {
    ...sharedPublicRoutesProps,
    name     : 'TermsOfService',
    path     : `${process.env.PUBLIC_URL}/termini-di-servizio`,
    component: Pages.System.TermsOfService,
    title    : 'Termini di Servizio'
  }

];
