import * as React from 'react';

import {
  Box,
  EmptyContent,
  Loader,
  Header, Container
} from '@appbuckets/react-bucket';

import { useClientRequest } from '../../modules/client';

import { PageComponent, useCurrentRoute } from '../../routes/app-router';


type District = { districtId: string, districtName: string };

const Survey: PageComponent<'Surveys'> = () => {

  const currentRoute = useCurrentRoute();
  const districtCode = currentRoute.search.get('X-EcoDistrict')
    || currentRoute.search.get('EcoDistrict')
    || currentRoute.search.get('x-ecoDistrict')
    || currentRoute.search.get('ecoDistrict')
    || currentRoute.search.get('district')
    || 'invalid';

  const requestState = useClientRequest<{ data: District }>({
    url               : `/district/${districtCode}`,
    request           : {
      withAccessToken: false
    },
    reloadDependencies: [ districtCode ]
  });

  if (requestState.isLoading) {
    return (
      <Box pt={6}>
        <Loader primary centered />
      </Box>
    );
  }

  if (requestState.error || !requestState.response) {
    return (
      <Container fixedTo={'tablet'}>
        <Box pt={6}>
          <Header
            icon={{
              name   : 'exclamation triangle',
              warning: true
            }}
            textAlign={'center'}
            content={'Attenzione'}
            subheader={[
              'Per accedere a questa pagina è necessario scarica l\'App EasyWaste',
              'sul tuo telefono e attraverso il menu seleziona la voce "Sondaggi e Proposte"'
            ].join(' ')}
            size={'large'}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container fixedTo={'tablet'}>
      <Box pt={6}>
        <Header
          icon={{
            name     : 'comments',
            iconStyle: 'regular',
            primary  : true
          }}
          textAlign={'center'}
          content={'Sondaggi e Proposte'}
          subheader={requestState.response.data.districtName}
          size={'large'}
        />
      </Box>

      <Box>
        <EmptyContent
          header={'Nessun Sondaggio'}
          content={'Al momento non è disponibile nessun sondaggio a cui partecipare'}
        />
      </Box>
    </Container>
  );

};

export default Survey;
