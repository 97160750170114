import * as React from 'react';

import ButterToast from 'butter-toast';

import { AppRouter } from '@appbuckets/app-router';
import { initClient } from './modules/client';

import { routes } from './routes';
import { RoutesDefinition } from './routes/routes-definition';


function App() {
  return (
    <React.Fragment>
      <AppRouter<RoutesDefinition>
        useRouteClassName
        routes={routes}
        defaultAppName={'Easy Waste'}
        hidePageWhileInitiallyLoading={true}
        hidePageWhileLoading={false}
        pageTitleWhileInitiallyLoading={'Caricamento...'}
        userHasAuth={false}
        isInitiallyLoading={false}
      />

      {/* Toast Container */}
      <ButterToast
        namespace={'toast-container'}
        className={'toasts'}
        position={{
          horizontal: 'POS_CENTER',
          vertical  : 'POS_BOTTOM'
        }}
      />
    </React.Fragment>
  );
}

export default initClient(App);
