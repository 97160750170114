import * as React from 'react';

import { Header, Message } from '@appbuckets/react-bucket';

import { ClientRequestError } from 'app/modules/client';


const RequestError: React.FunctionComponent<Partial<ClientRequestError>> = (props) => (
  <Message danger className={'request-error'}>
    <Header
      content={props.error ?? 'Error'}
      subheader={props.message}
    />
  </Message>
);

export default RequestError;
