import * as React from 'react';
import * as ReactDOM from 'react-dom';


/* --------
 * Load Style
 * -------- */
import './assets/styles/index.scss';


/* --------
 * Import Base App
 * -------- */
import EasyWasteApp from './app/App';


/* --------
 * Render the App
 * -------- */
ReactDOM.render(
  <EasyWasteApp />,
  document.getElementById('root')
);
